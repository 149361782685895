import React from "react";
import { connect } from "react-redux";
import { ErrorType, StateType } from "../../../../../State/Store";
import Records, { Record, RecordObject } from "../../../../../State/Records";
import { pushRoute } from "../../../../../State/Router";
import { trans } from "../../../../../Tools/LocaleHandler";
import PlayArrow from "@mui/icons-material/PlayArrow";
import Close from "@mui/icons-material/Close";
import Edit from "@mui/icons-material/Edit";
import Label from "../../../../Util/Label";
import Button from "../../../../../Layout/Components/Button";
import HttpClient from "../../../../../State/HttpClient";
import Toast from "../../../../../Layout/Common/Toast";
import Divider from "@mui/material/Divider";
import { Link } from "../../../../../State/Router";
import PageNotFound from "../../../../Layout/Views/PageNotFound";
import FormBuilder, {
  FormState,
} from "../../../../../Tools/FormBuilder/FormBuilder";
import PasswordField from "../../../../FormFields/PasswordField";
import Field from "../../../../../Tools/FormBuilder/Field";
import ErrorMsg from "../../../../../Layout/Components/ErrorMsg";
import Form from "../../../../../Layout/Components/Form";
import HiddenField from "../../../../FormFields/HiddenField";
import { auth } from "../../../../Root";

const styles = {
  segment: {
    fontFamily: "Lato",
    fontSize: 20,
    marginBottom: 25,
    color: "#0A3B60",
  },
  span: {
    fontFamily: "Lato",
    color: "#aaa",
    fontSize: 18,
    minWidth: 100,
    textAlign: "right" as const,
    padding: 20,
    paddingRight: 50,
  },
};

type RouteParams = { uuid: string };
export default connect((state: StateType, props: any) => {
  const authObject = state.app.authObject;
  const params = props.rstate.params;
  params.uuid =
    params.uuid ||
    (authObject && authObject.ref.repository === "User"
      ? authObject.ref.uuid
      : null);
  const associationMembershipsSignature = [
    "User",
    "AssociationMembership",
    "user",
    params.uuid,
  ].join(":");

  return {
    authObject,
    collectionSet: {
      associationMemberships: Records.mapRefsToRecords(
        state.collections[associationMembershipsSignature]
      ),
    },
    recordErrors: state.errors.records || {},
    params,
    record: Records.getRecord("User", params.uuid),
  };
})(
  (props: {
    authObject: RecordObject;
    collectionSet: { [key: string]: Record[] };
    record: Record;
    recordErrors: { [key: string]: ErrorType };
    params: RouteParams;
  }) => {
    props.record || Records.retrieve("User", props.params.uuid);
    props.collectionSet.associationMemberships ||
      Records.collect(
        "User",
        "AssociationMembership",
        "user",
        props.params.uuid
      );

    const notFound =
      props.recordErrors &&
      props.recordErrors.User &&
      (props.recordErrors.User as any)[props.params.uuid] === 404;
    if (notFound) {
      return <PageNotFound />;
    }

    if (!props.record) {
      return null;
    }

    const [state, setState] = React.useState({ showPasswordChangeForm: false });

    return (
      <div style={{ paddingLeft: "4%", paddingRight: "4%" }}>
        <div
          style={{
            fontFamily: "Lato",
            color: "#0A3B60",
            display: "flex",
            flexDirection: "row",
            fontSize: 16,
            fontWeight: "bold",
            justifyContent: "space-between",
            marginBottom: 24,
            marginTop: 24,
          }}
        >
          {trans(Label.ADMIN__USER_VIEW)}
          <Edit
            onClick={() => pushRoute(`/Admin/EditUser/${props.record.uuid}`)}
            style={{ cursor: "pointer" }}
          />
        </div>

        <div
          style={{
            backgroundColor: "#fff",
            padding: 48,
            paddingLeft: 72,
            paddingRight: 72,
            borderRadius: 10,
          }}
        >
          <table>
            <tbody>
              <tr>
                <td style={styles.span}>{trans(Label.FORM__EMAIL)}</td>
                <td style={styles.segment}>{props.record.email}</td>
              </tr>
              <tr>
                <td style={styles.span}>{trans(Label.FORM__FIRST_NAME)}</td>
                <td style={styles.segment}>{props.record.firstName}</td>
              </tr>
              <tr>
                <td style={styles.span}>{trans(Label.FORM__LAST_NAME)}</td>
                <td style={styles.segment}>{props.record.lastName}</td>
              </tr>
              <tr>
                <td style={styles.span}>{trans(Label.FORM__PHONE_NUMBER)}</td>
                <td style={styles.segment}>{props.record.phoneNumber}</td>
              </tr>
              <tr>
                <td style={styles.span}>
                  {trans(Label.FORM__ORGANISATION_NAME)}
                </td>
                <td style={styles.segment}>{props.record.organisationName}</td>
              </tr>
              <tr>
                <td style={styles.span}>
                  {trans(Label.FORM__ORGANISATION_POSITION)}
                </td>
                <td style={styles.segment}>
                  {props.record.organisationPosition}
                </td>
              </tr>
              <tr>
                <td style={styles.span}>{trans(Label.FORM__CREATED_AT)}</td>
                <td style={styles.segment}>{props.record.created_at}</td>
              </tr>
              <tr>
                <td style={styles.span}>
                  {trans(Label.FORM__EMAIL_CONFIRMED)}
                </td>
                <td style={styles.segment}>
                  {props.record.activationToken
                    ? trans(Label.UI__NO)
                    : trans(Label.UI__YES)}
                </td>
              </tr>
              <tr>
                <td style={styles.span}>{trans(Label.FORM__ID)}</td>
                <td style={styles.segment}>
                  {props.record.id} : {props.record.uuid}
                </td>
              </tr>
            </tbody>
          </table>

          <div style={styles.segment}>
            {props.record.activationToken && (
              <Button
                color={"#4daff0"}
                style={{ marginLeft: 20 }}
                title={"Activate Manually"}
                onPress={() => {
                  HttpClient.action("manualActivation", "User", {
                    data: {
                      userUuid: props.record.uuid,
                    },
                  })
                    .then((record) => {
                      Records.edit("User", record.uuid, record, true).then(
                        () => {
                          Toast.show({
                            type: "info",
                            text: trans(Label.UI__SUCCESS),
                          });
                        }
                      );
                    })
                    .catch((ex) => {
                      console.warn(ex);
                      Toast.show({
                        type: "warning",
                        text: trans(ex.text || ex),
                      });
                    });
                }}
              />
            )}
          </div>

          {(props.record.uuid === props.authObject.ref.uuid ||
            !!props.authObject.record.supergod) && (
              <div style={styles.segment}>
                {!state.showPasswordChangeForm && (
                  <Button
                    color={"#4daff0"}
                    title={trans(Label.FORM__PASSWORD_CHANGE)}
                    onPress={() =>
                      setState({ ...state, showPasswordChangeForm: true })
                    }
                  />
                )}
                {state.showPasswordChangeForm && (

                  <FormBuilder
                    action={(blueprint, done) => {
                      HttpClient.action("passwordChange", "User", {
                        blueprint,
                      })
                        .then((res) => {
                          if (res.authObject) {
                            auth(res.authObject, true);
                          }
                          setState({ ...state, showPasswordChangeForm: false });
                        })
                        .catch((ex) => {
                          console.error("Error response:", ex);
                          Toast.show({
                            type: "warning",
                            text: trans(ex.text || ex),
                          });
                        })
                        .finally(() => {
                          done(true);
                        });
                    }}
                    key={`LandUserChangePassword`}
                    fields={{
                      uuid: new HiddenField(
                        "form-landUserChPass-uuid-id",
                        Field.FLAG_REQUIRED,
                        props.record.uuid
                      ),
                      ...(props.record.associationAdmin
                        ? {
                          passwordOld: new PasswordField(
                            "form-landUserChPass-oldPassword-id",
                            Field.FLAG_REQUIRED,
                            null,
                            {
                              label: trans(Label.FORM__OLD_PASSWORD),
                            }
                          ),
                        }
                        : {}),
                      password: new PasswordField(
                        "form-landUserChPass-password-id",
                        Field.FLAG_REQUIRED,
                        null,
                        {
                          label: trans(Label.FORM__PASSWORD),
                          validations: ['passwordStrength']
                        }
                      ),
                    }}
                    metas={{
                      passwordConfirm: new PasswordField(
                        "form-landUserChPass-passwordConfirm-id",
                        Field.FLAG_REQUIRED,
                        null,
                        {
                          label: trans(Label.FORM__PASSWORD_CONFIRM),
                          matches: "form-landUserChPass-password-id",
                        }
                      ),
                    }}
                    layout={(form: FormState) => (
                      <Form
                        style={{
                          alignItems: "center",
                          backgroundColor: "#fafafa",
                          borderRadius: 20,
                          display: "flex",
                          flexDirection: "column",
                          padding: 20,
                        }}
                      >
                        <Close
                          onClick={() =>
                            setState({ ...state, showPasswordChangeForm: false })
                          }
                          style={{
                            alignSelf: "flex-start",
                            cursor: "pointer",
                            marginBottom: 10,
                          }}
                        />

                        <div style={{ marginBottom: 20 }}>
                          {props.record.associationAdmin && (
                            <>
                              <ErrorMsg
                                error={form.getError("form-landUserChPass-oldPassword-id", true)}
                              />
                              {form.fields.passwordOld}
                              <div style={{ margin: 20 }}></div>
                            </>
                          )}

                          <ErrorMsg
                            error={form.getError(
                              "form-landUserChPass-password-id",
                              true
                            )}
                          />
                          {form.fields.password}

                          <div style={{ margin: 20 }}></div>

                          <ErrorMsg
                            error={form.getError(
                              "form-landUserChPass-passwordConfirm-id",
                              true
                            )}
                          />
                          {form.metas.passwordConfirm}
                        </div>

                        <Button
                          disabled={
                            form.buttons.submit.disabled && form.touched
                          }
                          color={"#4daff0"}
                          title={trans(Label.FORM__PASSWORD_CHANGE)}
                          onPress={() => form.buttons.submit.action()}
                        />
                      </Form>
                    )}
                  />
                )}
              </div>
            )}

          {props.record.associationAdmin && (
            <React.Fragment>
              <div style={styles.segment}>
                <span style={styles.span}>{trans(Label.FORM__IS_ADMIN)}</span>
                &nbsp;&nbsp;
                <Link
                  to={`/Admin/LandAssociation/${props.record.associationAdmin.uuid}`}
                >
                  <span style={{ fontSize: 18, lineHeight: "22px" }}>
                    {props.record.associationAdmin.name}
                  </span>
                </Link>
              </div>

              {!!props.authObject.record.supergod && (
                <div style={styles.segment}>
                  <Button
                    color={"#ff3333"}
                    title={trans(Label.FORM__REMOVE_ADMIN_STATUS)}
                    onPress={() => {
                      HttpClient.action("removeAdmin", "User", {
                        data: {
                          userUuid: props.record.uuid,
                        },
                      })
                        .then((record) => {
                          Records.edit("User", record.uuid, record, true).then(
                            () => {
                              Toast.show({
                                type: "info",
                                text: trans(Label.UI__SUCCESS),
                              });
                            }
                          );
                        })
                        .catch((ex) => {
                          console.warn(ex);
                          Toast.show({
                            type: "warning",
                            text: trans(ex.text || ex),
                          });
                        });
                    }}
                  />
                </div>
              )}
            </React.Fragment>
          )}

          <Divider style={{ marginBottom: 24 }} />

          <div style={styles.segment}>
            {trans(Label.ADMIN__DISPLAY_ASSOCIATION_MEMBERSHIP_TITLE)}
          </div>
          {props.collectionSet.associationMemberships &&
            props.collectionSet.associationMemberships.map(
              (associationMembership) => (
                <div
                  key={associationMembership.uuid}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    minHeight: 120,
                    maxHeight: 175,
                    marginBottom: 25,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#f7f7f7",
                      borderBottomLeftRadius: 10,
                      borderTopLeftRadius: 10,
                      flex: 1,
                      padding: 20,
                    }}
                  >
                    <div style={{ display: "flex", marginBottom: 10 }}>
                      <span style={{ color: "#aaa", flex: 1 }}>
                        {trans(Label.FORM__NAME)}
                      </span>
                      <Link
                        to={`/Admin/LandAssociation/${associationMembership.association.uuid}`}
                        style={{ flex: 3 }}
                      >
                        <span style={{ color: "#333", fontWeight: "bold" }}>
                          {associationMembership.association.name}
                        </span>
                      </Link>
                    </div>
                    <div style={{ display: "flex", marginBottom: 10 }}>
                      <span style={{ color: "#aaa", flex: 1 }}>
                        {trans(Label.FORM__CREATED_AT)}
                      </span>
                      <span style={{ flex: 3 }}>
                        {associationMembership.created_at}
                      </span>
                    </div>
                    <div style={{ display: "flex", marginBottom: 10 }}>
                      <span style={{ color: "#aaa", flex: 1 }}>
                        {trans(Label.FORM__STATE)}
                      </span>
                      <span style={{ flex: 3 }}>
                        {associationMembership.state === "approved" &&
                          trans(Label.ASSOCIATION_MEMBERSHIP__APPROVED)}
                        {associationMembership.state === "rejected" &&
                          trans(Label.ASSOCIATION_MEMBERSHIP__REJECTED)}
                        {associationMembership.state === "pending" &&
                          trans(Label.ASSOCIATION_MEMBERSHIP__PENDING)}
                      </span>
                    </div>
                    {!props.record.associationAdmin &&
                      !!props.authObject.record.supergod && (
                        <div style={{ marginBottom: 10, marginTop: 20 }}>
                          <Button
                            color={"#4daff0"}
                            title={trans(Label.FORM__MAKE_ADMIN)}
                            onPress={() => {
                              HttpClient.action("makeAdmin", "User", {
                                data: {
                                  userUuid: props.record.uuid,
                                  associationUuid:
                                    associationMembership.association.uuid,
                                },
                              })
                                .then((record) => {
                                  Records.edit(
                                    "User",
                                    record.uuid,
                                    record,
                                    true
                                  ).then(() => {
                                    Toast.show({
                                      type: "info",
                                      text: trans(Label.UI__SUCCESS),
                                    });
                                  });
                                })
                                .catch((ex) => {
                                  console.warn(ex);
                                  Toast.show({
                                    type: "warning",
                                    text: trans(ex.text || ex),
                                  });
                                });
                            }}
                          />
                        </div>
                      )}
                  </div>

                  <div
                    // hitSlop={{ bottom: 5, left: 5, right: 5, top: 5 }}
                    onClick={() =>
                      pushRoute(
                        `/Admin/LandAssociationMembership/${associationMembership.uuid}`
                      )
                    }
                    style={{
                      alignItems: "center",
                      backgroundColor: "#e7e7e7",
                      borderBottomRightRadius: 10,
                      borderTopRightRadius: 10,
                      display: "flex",
                      justifyContent: "center",
                      width: 30,
                    }}
                  >
                    <Link
                      to={`/Admin/LandAssociationMembership/${associationMembership.uuid}`}
                    >
                      <PlayArrow
                        htmlColor={"#c7c7c7"}
                        style={{ cursor: "pointer" }}
                      />
                    </Link>
                  </div>
                </div>
              )
            )}
        </div>
      </div>
    );
  }
);
